import { InjectionToken, inject } from "@angular/core";
import { PersistedUser, UserRights } from "./user.model";
import { APP_CONFIG, AppHostService, LocalStorageStore, PersistentStore } from "app/core";
import { UserStore } from "./user.store";
import { UserStoreIdrhImpl } from "./user.idrh.store";
import { UserStoreDefaultImpl } from "./user.default.store";
import { persistentUtil } from "app/core/util/persistent.util";
import { OidcPersistedUser } from "./oidc-persisted-user";

/** Dependency injection tokens */
export const USER_RIGHTS_PERSISTENT_STORE = new InjectionToken<PersistentStore<UserRights>>(
  "USER_RIGHTS_PERSISTENT_STORE",
  {
    factory: () => {
      const config = inject(APP_CONFIG);
      const storeId = config.botName + "UserRights";
      const modelKeys = ["canWrite"];
      const eventId = "canWrite";
      return persistentUtil.buildPersistentStore<UserRights>(storeId, modelKeys, 0, eventId);
    },
  }
);

export const USER_STORE = new InjectionToken<UserStore>("USER_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);

    if (config.idrhSupport) {
      if (config.iframeSandbox) {
        console.error("IDRH and sandbox are incompatible");
      }

      let userPersistentStore;
      if (config.hasOidcSupport) {
        userPersistentStore = new OidcPersistedUser(window.sessionStorage);
      } else {
        userPersistentStore = new LocalStorageStore<PersistedUser>(
          window.localStorage,
          "",
          ["idrh", "IDRH", "user-jwt"],
          0
        );
      }

      return new UserStoreIdrhImpl(
        config.idrhSupport.required,
        inject(AppHostService),
        userPersistentStore,
        config
      );
    } else {
      return new UserStoreDefaultImpl();
    }
  },
});
