import { Injectable } from "@angular/core";
import { PersistentStore } from "app/core";
import { jwtDecode } from "jwt-decode";
import { Observable, of } from "rxjs";
import { PersistedUser } from "./user.model";

@Injectable({ providedIn: "root" })
export class OidcPersistedUser implements PersistentStore<PersistedUser> {
  constructor(private sessionStorageService: Storage) {}
  get(): Observable<PersistedUser> {
    const oidcToken = this.sessionStorageService.getItem("chatbot_oidc_id_token") ?? "";
    let idrhFromToken = "";
    if (oidcToken) {
      idrhFromToken = jwtDecode(oidcToken)?.sub ?? "";
    }
    return of({
      idrh: idrhFromToken,
      IDRH: idrhFromToken,
      "user-jwt": "",
    });
  }

  set(): void {
    console.error("Forbidden : Oidc can not be set by chatbot");
  }

  clear(): void {
    console.error("Forbidden : Oidc can not be clear by chatbot");
  }
}
