import { UserStore } from "./user.store";
import { AppConfig, AppHostService, PersistentStore } from "app/core";
import { PersistedUser, User } from "./user.model";
import { toSignal } from "@angular/core/rxjs-interop";

/** A store to access data on the current user, its IDRH in particular. */
export class UserStoreIdrhImpl implements UserStore {
  private persistedUserSignal;

  constructor(
    private idrhRequired: boolean,
    private hostService: AppHostService,
    private persistentStore: PersistentStore<PersistedUser>,
    private config: AppConfig
  ) {
    this.persistedUserSignal = toSignal(this.persistentStore.get());
  }

  /** @return Data on the current user */
  public get(): User {
    const persistedUser = this.persistedUserSignal() ?? {
      idrh: "",
      IDRH: "",
      "user-jwt": "",
    };

    let actualDefaultIdrh = "";
    if (
      this.config.whereToSetDefaultIdrh &&
      this.config.whereToSetDefaultIdrh.some((substring) =>
        this.hostService.currentUrl.includes(substring)
      )
    ) {
      if (this.config.defaultIdrhBotTest && this.hostService.isTestBot) {
        actualDefaultIdrh = this.config.defaultIdrhBotTest;
      }
      if (this.config.defaultIdrh && !this.hostService.isTestBot) {
        actualDefaultIdrh = this.config.defaultIdrh;
      }
    }

    const idrh = (
      persistedUser.idrh ||
      persistedUser.IDRH ||
      actualDefaultIdrh
    ).toLocaleLowerCase();
    return { idrh, token: persistedUser["user-jwt"] ?? "" };
  }

  /** @return Whether an internal user has a valid IDRH */
  isUserValid() {
    //TODO : only for test
    return true;

    const user = this.get();
    if (user.idrh) {
      // always show on service-now
      if (this.hostService.currentUrl.includes("service-now")) {
        return true;
      }
      // check the idrh
      if (user.idrh.startsWith("p")) {
        if (user.idrh.length === 7) {
          return true;
        } else {
          console.error("idrh forbidden: length is not 7");
        }
      } else {
        console.error("idrh forbidden: does not start with P");
      }
    }
    return false;
  }
  isMissingRequiredUser() {
    return this.idrhRequired && !this.isUserValid();
  }
}
